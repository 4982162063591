.Gmodal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}

@media only screen and (max-width: 500px) {
    .Gmodal{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 300px;
        background-color: rgb(0,0,0);
        background-color: rgba(0,0,0,0.4);
    }
}