.rounded-circle{
    padding: 30px 0px;
}

.gg{
    background-image: url("/public/img/bg.jpg");
    background-position: cover;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.AlbumBg{
    background-image: url("/public/img/album.jpg");
    background-position: cover;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.Bottom{
    background-image: url("https://www.sloughislamictrust.org.uk/images/Blogs/Covers/Cover-2021-01-29-1611937973.jpg");
    background-position: cover;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}
.Top{
    background-image: url("/public/img/bg1.jpg");
    background-position: cover;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

@media only screen and (max-width: 500px) {
    .rounded-circle{
        padding: 15px 0px;
    }
}