  .arrow-btn1 {
    width: 35px;
    margin-left: 5px; 
    background: none;
    border: 0px;
  }
  .arrow-btn2 {
    width: 35px;
    background: none;
    border: 0px;
  }
  
  @media only screen and (min-width: 320px) {
    .each-slide-effect{
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: cover;
      height: 27vh;
    }
    .each-slide-effect img{
      height: 100%
    }
}
@media only screen and (min-width: 400px) {
  .each-slide-effect{
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 27vh;
  }
  .each-slide-effect img{
    height: 100%
  }
}

@media only screen and (min-width: 500px) {
  .each-slide-effect{
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 38vh;
  }
  .each-slide-effect img{
    height: 100%
  }
}

@media only screen and (min-width: 700px) {
  .each-slide-effect{
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 47vh;
  }
  .each-slide-effect img{
    height: 100%
  }
}

@media only screen and (min-width: 900px) {
  .each-slide-effect{
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 89vh;
  }
  .each-slide-effect img{
    height: 100%
  }
}